<template>
  <!-- begin::Quick Panel -->
  <div id="kt_quick_panel" ref="kt_quick_panel" class="kt-quick-panel">
    <a href="#" class="kt-quick-panel__close" id="kt_quick_panel_close_btn"
      ><i class="flaticon2-delete"></i
    ></a>

    <div class="kt-quick-panel__nav">
      <ul
        class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand  kt-notification-item-padding-x"
        role="tablist"
      >
        <li class="nav-item active">
          <a
            class="nav-link active"
            data-toggle="tab"
            href="#kt_quick_panel_tab_notifications"
            role="tab"
            >Notifications</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#kt_quick_panel_tab_settings"
            role="tab"
            >Settings</a
          >
        </li>
      </ul>
    </div>

    <div class="kt-quick-panel__content">
      <div class="tab-content">
        <div
          class="tab-pane fade show kt-scroll active"
          id="kt_quick_panel_tab_notifications"
          role="tabpanel"
        >
          <div class="kt-notification">
            <a href="#/work-orders/1" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="flaticon2-folder kt-font-info"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  Alert on WO#32545
                </div>
                <div class="kt-notification__item-time">
                  2020-06-10 Delivery deadline expires
                </div>
              </div>
            </a>
            <a href="#/lou" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="flaticon2-document kt-font-brand"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  Alert on Surveyor's Attendance #545
                </div>
                <div class="kt-notification__item-time">
                  2020-06-10 #545 Need your attention
                </div>
              </div>
            </a>
            <a href="#/lou" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="flaticon2-document kt-font-brand"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  Alert on WO#32545
                </div>
                <div class="kt-notification__item-time">
                  2020-06-10 Replace LOU
                </div>
              </div>
            </a>
            <a href="#/admin/users" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="flaticon2-user kt-font-success"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  New User Registered
                </div>
                <div class="kt-notification__item-time">
                  2 hrs ago
                </div>
              </div>
            </a>
            <a href="#/reports" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="flaticon2-sheet kt-font-danger"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  New Report created
                </div>
                <div class="kt-notification__item-time">
                  3 hrs ago
                </div>
              </div>
            </a>
            <a href="#/expenses" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="flaticon-price-tag kt-font-warning"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  New expense has been uploaded
                </div>
                <div class="kt-notification__item-time">
                  5 hrs ago
                </div>
              </div>
            </a>
            <a href="#/invoices" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="flaticon-layer kt-font-success"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  New Invoice created
                </div>
                <div class="kt-notification__item-time">
                  12 hrs ago
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
          class="tab-pane kt-quick-panel__content-padding-x fade kt-scroll"
          id="kt_quick_panel_tab_settings"
          role="tabpanel"
        >
          <form class="kt-form">
            <div class="kt-heading kt-heading--sm kt-heading--space-sm">
              Notifiactions
            </div>

            <div class="form-group form-group-xs row">
              <label class="col-8 col-form-label">New User Notification:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--success kt-switch--sm">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_1"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-xs row">
              <label class="col-8 col-form-label"
                >New Folder Notification:</label
              >
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--success kt-switch--sm">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_1"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-xs row">
              <label class="col-8 col-form-label"
                >New L.O.U Notification:</label
              >
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--success kt-switch--sm">
                  <label>
                    <input type="checkbox" name="quick_panel_notifications_2" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-last form-group-xs row">
              <label class="col-8 col-form-label"
                >New Budget Notification:</label
              >
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--success kt-switch--sm">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_2"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-last form-group-xs row">
              <label class="col-8 col-form-label"
                >New Inbound Notification:</label
              >
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--success kt-switch--sm">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_2"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div
              class="kt-separator kt-separator--space-md kt-separator--border-dashed"
            ></div>

            <div class="kt-heading kt-heading--sm kt-heading--space-sm">
              Dashboard
            </div>

            <div class="form-group form-group-xs row">
              <label class="col-8 col-form-label">Users:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--sm kt-switch--danger">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_3"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-xs row">
              <label class="col-8 col-form-label">Folders:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--sm kt-switch--danger">
                  <label>
                    <input type="checkbox" name="quick_panel_notifications_3" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-last form-group-xs row">
              <label class="col-8 col-form-label">Inbounds:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--sm kt-switch--danger">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_4"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-last form-group-xs row">
              <label class="col-8 col-form-label">L.O.U.:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--sm kt-switch--danger">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_4"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-last form-group-xs row">
              <label class="col-8 col-form-label">Time:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--sm kt-switch--danger">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_4"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div
              class="kt-separator kt-separator--space-md kt-separator--border-dashed"
            ></div>

            <div class="kt-heading kt-heading--sm kt-heading--space-sm">
              Mail Alerts
            </div>

            <div class="form-group form-group-xs row">
              <label class="col-8 col-form-label">New User:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--sm kt-switch--brand">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_5"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-xs row">
              <label class="col-8 col-form-label">L.O.U.:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--sm kt-switch--brand">
                  <label>
                    <input type="checkbox" name="quick_panel_notifications_5" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group form-group-last form-group-xs row">
              <label class="col-8 col-form-label">New Inboice:</label>
              <div class="col-4 kt-align-right">
                <span class="kt-switch kt-switch--sm kt-switch--brand">
                  <label>
                    <input
                      type="checkbox"
                      checked="checked"
                      name="quick_panel_notifications_6"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Quick Panel -->
</template>

<script>
import KTOffcanvas from "@/assets/js/offcanvas.js";

export default {
  name: "KTQuickPanel",
  props: {},
  components: {},
  computed: {},
  mounted() {
    new KTOffcanvas(this.$refs["kt_quick_panel"], {
      overlay: true,
      baseClass: "kt-quick-panel",
      closeBy: "kt_quick_panel_close_btn",
      toggleBy: "kt_quick_panel_toggler_btn"
    });
  },
  methods: {}
};
</script>
