<template>
  <form>
    <!--begin: Head -->
    <div
      class="kt-head kt-head--skin-dark"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h3 class="kt-head__title">
        User Quick Actions
      </h3>
    </div>
    <!--end: Head -->

    <!--begin: Grid Nav -->
    <div class="kt-grid-nav kt-grid-nav--skin-light">
      <div class="kt-grid-nav__row">
        <a class="kt-grid-nav__item" @click="createRecord('/work-order-new')" >
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3.5 21h17a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0020.5 7H10L7.44 4.44A1.5 1.5 0 006.378 4H3.5A1.5 1.5 0 002 5.5v14A1.5 1.5 0 003.5 21z" fill="#000" opacity=".3"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Work Orders</span>
          <span class="kt-grid-nav__desc">New</span>
        </a>
        <a class="kt-grid-nav__item" @click="createRecord('/time/new')" >
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12 22a9 9 0 110-18 9 9 0 010 18z" fill="#000" opacity=".3"/><path d="M11.963 7.5h.085a.5.5 0 01.497.45L13 12.5l3.248 1.856a.5.5 0 01.252.434V15a.382.382 0 01-.482.368l-4.62-1.26a.5.5 0 01-.366-.52l.432-5.626a.5.5 0 01.499-.462z" fill="#000"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Timelog</span>
          <span class="kt-grid-nav__desc">Star Clock</span>
        </a>
        <a class="kt-grid-nav__item" @click="createRecord('/lou/new')" >
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4.857 1h6.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0118 6.529v12.554c0 1.79-.02 1.917-1.857 1.917H4.857C3.02 21 3 20.874 3 19.083V2.917C3 1.127 3.02 1 4.857 1zM8 12a1 1 0 000 2h7a1 1 0 000-2H8zm0 4a1 1 0 000 2h3a1 1 0 000-2H8z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M6.857 3h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0121 8.529v12.554c0 1.79-.02 1.917-1.857 1.917H6.857C5.02 23 5 22.874 5 21.083V4.917C5 3.127 5.02 3 6.857 3zM8 12a1 1 0 000 2h7a1 1 0 000-2H8zm0 4a1 1 0 000 2h3a1 1 0 000-2H8z" fill="#000" fill-rule="nonzero"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Letter of Undertaking</span>
          <span class="kt-grid-nav__desc">New</span>
        </a>
      </div>
      <div class="kt-grid-nav__row">
        <a class="kt-grid-nav__item" @click="createRecord('/proformas/new')" >
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" fill="#000" fill-rule="nonzero" opacity=".3"/><rect fill="#000" x="6" y="11" width="9" height="2" rx="1"/><rect fill="#000" x="6" y="15" width="5" height="2" rx="1"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Proformas</span>
          <span class="kt-grid-nav__desc">New</span>
        </a>
        <a class="kt-grid-nav__item" @click="createRecord('/quotations/new')" >
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" fill="#000" fill-rule="nonzero" opacity=".3"/><rect fill="#000" x="6" y="11" width="9" height="2" rx="1"/><rect fill="#000" x="6" y="15" width="5" height="2" rx="1"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Quotations</span>
          <span class="kt-grid-nav__desc">New</span>
        </a>
        <a class="kt-grid-nav__item" @click="createRecord('/nominations/new')" >
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" fill="#000" fill-rule="nonzero" opacity=".3"/><rect fill="#000" x="6" y="11" width="9" height="2" rx="1"/><rect fill="#000" x="6" y="15" width="5" height="2" rx="1"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Nomination</span>
          <span class="kt-grid-nav__desc">New</span>
        </a>
      </div>
    </div>
    <!--end: Grid Nav -->
  </form>
</template>

<script>
export default {
  name: "KTDropdownQuickAction",

  methods: {
    createRecord(link) {
      if(link == this.$route.path) {
        location.reload();
      } else {
        window.location = '#' + link;
      }
    }
  },

  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    }
  }
};
</script>
